import type { PaginatedResponse } from '@/api/model/PaginatedResponse';
import { useMenuStore } from '@/stores/menuStore';
import { defineStore } from 'pinia';

import type SKU from '@/api/model/SKU';
import useSKUService from '@/api/SKUService';
import { useOrganisationStore } from '@/stores/organisationStore';

const skuService = useSKUService();

export const useSkuStore = defineStore('sku', {
    state: () => ({
        skus: [] as SKU[],
        loading: false,
        loaded: false,
        problemCount: 0,
    }),
    getters: {},
    actions: {
        fetch() {
            if (this.loading || this.skus.length > 0) {
                return Promise.resolve([]);
            }
            this.loading = true;
            const organisationStore = useOrganisationStore();
            return skuService
                .getAll(organisationStore.current_organisation, false)
                .then((skus: SKU[] | PaginatedResponse<SKU>) => {
                    this.loaded = true;
                    if (Array.isArray(skus)) {
                        return (this.skus = skus);
                    } else {
                        return (this.skus = skus.data);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        refresh() {
            this.skus = [];
            if (this.loaded) {
                this.loaded = false;
                this.fetch();
            }
        },
        async fetchCount() {
            const organisationStore = useOrganisationStore();
            try {
                this.problemCount = await skuService.getProblemCount(organisationStore.current_organisation);
            } catch (error) {
                console.error(error);
                this.problemCount = 0;
            }
            const menuStore = useMenuStore();
            if (this.problemCount === 0) {
                menuStore.removeBadge('SKUs');
                return;
            } else {
                menuStore.addBadge('SKUs', this.problemCount, 'danger');
            }
        },
    },
});
