import type { ISKU } from '@/api/model/SKU';
import type SKU from '@/api/model/SKU';
import type { ToJSON } from '@/api/model/ToJSON';
import { DateTime } from 'luxon';
import { ulid } from 'ulid';

export interface WarehouseTransferItemJson {
    id?: number;
    created_at: string;
    updated_at: string;
    sku_id: number;
    sku: ISKU;
    quantity_shipped: number;
    quantity_received: number;
    quantity_expected: number;
    quantity_in_case: number;
    ulid: string;
}

export class WarehouseTransferItem implements ToJSON<WarehouseTransferItemJson> {
    id?: number;
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();
    quantity_shipped = 0;
    quantity_received = 0;
    quantity_expected = 0;
    quantity_in_case = 0;
    sku_id = 0;
    sku?: SKU = undefined;
    ulid = ulid().toLowerCase();

    static fromJSON(json: WarehouseTransferItemJson): WarehouseTransferItem {
        //console.debug("WarehouseTransferItem.fromJSON()", json);
        const item = Object.create(WarehouseTransferItem.prototype);
        return Object.assign(item, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
        });
    }

    toJSON(): WarehouseTransferItemJson {
        //console.debug("WarehouseTransferItem.toJSON()", this);
        return Object.assign({} as WarehouseTransferItemJson, this, {
            created_at: this.created_at.toISO(),
            updated_at: this.updated_at.toISO(),
        });
    }
}
