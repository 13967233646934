import type { ISKU } from './SKU';
import SKU from './SKU';
import type { SupplierJson } from '@/api/model/Supplier';
import Supplier from '@/api/model/Supplier';
import { DateTime } from 'luxon';

export interface IProduct {
    id?: number;
    created_at: string;
    updated_at: string;
    name: string;
    supplier_id: number;
    organisation_id: number;
    skus: Array<ISKU>;
    suppliers: Array<SupplierJson>;
    pivot: object;
    cogs: number | null;
    cogs_currency: string | null;
}

type LeadTime = {
    supplier_id: number;
    product_id: number;
    production_lead_days: number;
    buffer_days: number;
};

export type WithLeadTime = {
    pivot: LeadTime;
};

export default class Product {
    id = 0;
    created_at: DateTime = DateTime.local();
    updated_at: DateTime = DateTime.local();
    name = '';
    supplier_id = 0;
    organisation_id = 0;
    skus: Array<SKU> = [];
    suppliers: Array<Supplier> = [];
    cogs: number | null = null;
    cogs_currency = 'USD';

    toJSON(): IProduct {
        //console.debug("Product.toJSON()", this);
        return Object.assign({} as IProduct, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: IProduct): Product {
        //console.debug("Product.fromJSON()", json);
        const user = Object.create(Product.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            skus: json.skus ? json.skus.map((sku) => SKU.fromJSON(sku)) : [],
            suppliers: json.suppliers ? json.suppliers.map((supplier) => Supplier.fromJSON(supplier)) : [],
            pivot: json.pivot ? json.pivot : {},
            cogs_currency: json.cogs_currency ? json.cogs_currency : 'USD',
        });
    }

    // For sorting
    static byName(p1: Product, p2: Product): number {
        if (p1.name > p2.name) {
            return 1;
        }

        if (p1.name < p2.name) {
            return -1;
        }

        return 0;
    }
}
