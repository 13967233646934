import { defineStore } from 'pinia';
import type ReportSource from '@/api/model/ReportSource';

import useReportSourceService from '@/api/ReportSourceService';
import { Organisation } from '@/api/model/Organisation';
import type { StoreReportSourceRequest } from '@/api/types/ReportSources';

interface ReportSourceState {
    reportSources: ReportSource[];
    loading: boolean;
}

const reportSourceService = useReportSourceService();

export const useReportSourceStore = defineStore('reportSource', {
    state: (): ReportSourceState => ({
        reportSources: [],
        loading: false,
    }),
    getters: {},
    actions: {
        fetch(): Promise<ReportSource[]> {
            if (this.loading || this.reportSources.length > 0) {
                return Promise.resolve([]);
            }
            return this.refresh(new Organisation());
        },
        refresh(organisation: Organisation): Promise<ReportSource[]> {
            this.loading = true;
            return reportSourceService
                .getAll(organisation)
                .then((reportSources) => {
                    return (this.reportSources = reportSources);
                })
                .catch((error) => {
                    this.reportSources = [];
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        store(organisation: Organisation, data: StoreReportSourceRequest): Promise<ReportSource> {
            return reportSourceService.create(organisation, data).then((response) => {
                this.reportSources = [...this.reportSources, response];
                return response;
            });
        },
        update(organisation: Organisation, reportSource: ReportSource): Promise<ReportSource> {
            return reportSourceService.update(organisation, reportSource).then((response) => {
                const index = this.reportSources.findIndex((reportSource) => reportSource.id === response.id);
                this.reportSources.splice(index, 1, response);
                return response;
            });
        },
    },
});
