import { DateTime } from 'luxon';

export type WarehouseInventory = {
    [key: string]: number;
};

export interface WarehouseSKUJson {
    quantity: number;
    sku_id: number;
    warehouse_id: number;
    inventory_cache: WarehouseInventory;
    out_of_stock_date: string | null;
}

export default class WarehouseSKU {
    quantity = 0;
    sku_id?: number;
    warehouse_id?: number;
    inventory_cache: WarehouseInventory = {};
    out_of_stock_date: DateTime | null = null;

    toJSON(): WarehouseSKUJson {
        //console.debug("WarehouseSKU.toJSON()", this);
        return Object.assign({} as WarehouseSKUJson, this, {});
    }

    static fromJSON(json: WarehouseSKUJson): WarehouseSKU {
        //console.debug("WarehouseSKU.fromJSON()", json);
        const warehouseSKU = Object.create(WarehouseSKU.prototype);
        return Object.assign(warehouseSKU, json, {
            out_of_stock_date: json.out_of_stock_date ? DateTime.fromISO(json.out_of_stock_date) : null,
        });
    }
}
