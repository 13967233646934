import type Country from '@/api/model/Country';
import type { IMarketplace } from '@/api/model/Marketplace';
import Marketplace from '@/api/model/Marketplace';
import type { Organisation } from '@/api/model/Organisation';
import type PageOptions from '@/api/model/PageOptions';
import type { PaginatedResponseJson } from '@/api/model/PaginatedResponse';
import { PaginatedResponse } from '@/api/model/PaginatedResponse';
import type { PurchaseOrderJson } from '@/api/model/PurchaseOrder';
import PurchaseOrder from '@/api/model/PurchaseOrder';
import type { Resource } from '@/api/model/Resource';
import type { ResourceCollection } from '@/api/model/ResourceCollection';
import type { ISKU, WithWarehouseSKU } from '@/api/model/SKU';
import SKU from '@/api/model/SKU';
import type { IWarehouse, WithTransfers } from '@/api/model/Warehouse';
import Warehouse from '@/api/model/Warehouse';
import type { WarehouseInventory } from '@/api/model/WarehouseSKU';
import type { WarehouseTransferJson } from '@/api/model/WarehouseTransfer';
import WarehouseTransfer from '@/api/model/WarehouseTransfer';
import axios from 'axios';

export type SalesDataRow = {
    period: string;
    quantity: number;
};

export interface SalesData {
    monthly_sales: SalesDataRow[];
    daily_sales: SalesDataRow[];
}

export type StockOutEntry = {
    title: string;
    months: number;
    days: number;
    date: string;
};
export type StockOut = {
    forecast: StockOutEntry[];
    '90d': StockOutEntry[];
};
export type StockOutData = {
    stock_out: StockOut;
};

export type Sold = {
    '30d': SoldItem;
    '365d': SoldItem;
    '90d': SoldItem;
    cm: SoldItem;
    forecast: SoldItem;
};

export type SoldItem = {
    title: string;
    total: number;
    per_month: number;
    per_day: number;
};

export type ByDayWarehouseInventory = { [key: number]: WarehouseInventory };

export type InventoryData = {
    data: {
        warehouses: (IWarehouse & WithWarehouseSKU & WithTransfers)[];
    };
};

export default function useSKUService() {
    return {
        async getAll(
            org: Organisation,
            include_sales_marketplaces: boolean | null,
            show_hidden: boolean = true,
            page: PageOptions | null = null,
        ): Promise<SKU[] | PaginatedResponse<SKU>> {
            const params = page?.toURLSearchParams() || new URLSearchParams();
            params.append('include_sales_marketplaces', include_sales_marketplaces ? '1' : '0');
            params.append('show_hidden', show_hidden ? '1' : '0');

            const response = await axios.get<ResourceCollection<ISKU> | PaginatedResponseJson<ISKU>>(
                `/api/organisations/${org.ulid}/skus?` + params.toString(),
            );

            if ('meta' in response.data) {
                return PaginatedResponse.fromJSON(response.data, (json: ISKU) => SKU.fromJSON(json));
            } else {
                return response.data.data.map((sku) => SKU.fromJSON(sku));
            }
        },

        async get(org: Organisation, id: number): Promise<SKU> {
            const response = await axios.get<Resource<ISKU>>(`/api/organisations/${org.ulid}/skus/${id}`);
            return SKU.fromJSON(response.data.data);
        },

        create(org: Organisation, sku_id: string): Promise<SKU> {
            return axios
                .post<Resource<ISKU>>(`/api/organisations/${org.ulid}/skus`, {
                    sku_id: sku_id,
                })
                .then((response) => SKU.fromJSON(response.data.data));
        },

        async getSalesMarketplaces(org: Organisation, id: number): Promise<Marketplace[]> {
            const response = await axios.get<ResourceCollection<IMarketplace>>(
                `/api/organisations/${org.ulid}/skus/${id}/sales-marketplaces/`,
            );
            return response.data.data.map((marketplace) => Marketplace.fromJSON(marketplace));
        },

        addMarketplace(org: Organisation, sku: SKU, country: Country, marketplace: Marketplace) {
            return axios.post(`/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/marketplaces/`, {
                marketplace_id: marketplace.id,
            });
        },

        removeMarketplace(org: Organisation, sku: SKU, marketplace: Marketplace, country: Country) {
            return axios.delete(
                `/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/marketplaces/${marketplace.id}`,
            );
        },

        async update(org: Organisation, sku: SKU) {
            const response = await axios.put<Resource<ISKU>>(`/api/organisations/${org.ulid}/skus/${sku.id}`, sku);
            return SKU.fromJSON(response.data.data);
        },

        async getSalesData(org: Organisation, sku: SKU, country: Country) {
            const response = await axios.get<SalesData>(
                `/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/sales`,
            );
            return response.data;
        },

        async getStockOut(org: Organisation, sku: SKU, country: Country) {
            const response = await axios.get<StockOutData>(
                `/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/stockout`,
            );
            return response.data;
        },

        async getSold(org: Organisation, sku: SKU, country: Country) {
            const response = await axios.get<Sold>(
                `/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/sold`,
            );
            return response.data;
        },

        async getPurchaseOrders(org: Organisation, sku: SKU, country: Country) {
            const response = await axios.get<ResourceCollection<PurchaseOrderJson>>(
                `/api/organisations/${org.ulid}/skus/${sku.id}/countries/${country.id}/purchase-orders`,
            );
            return response.data.data.map((po) => PurchaseOrder.fromJSON(po));
        },

        async getInventory(
            current_organisation: Organisation,
            sku: SKU,
        ): Promise<(Warehouse & WithWarehouseSKU & WithTransfers)[]> {
            if (sku.id === 0) {
                return [];
            }

            const response = await axios.get<InventoryData>(
                `/api/organisations/${current_organisation.ulid}/skus/${sku.id}/inventory`,
            );

            return response.data.data.warehouses.map(
                (warehouse) => Warehouse.fromJSON(warehouse) as Warehouse & WithWarehouseSKU & WithTransfers,
            );
        },
        async getTransfers(org: Organisation, sku: SKU): Promise<WarehouseTransfer[]> {
            if (sku.id === undefined) {
                return [];
            }

            const response = await axios.get<ResourceCollection<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/skus/${sku.id}/transfers`,
            );
            return response.data.data.map((transfer) => WarehouseTransfer.fromJSON(transfer));
        },
        async getProblemCount(org: Organisation): Promise<number> {
            const response = await axios.get<{ count: number }>(`/api/organisations/${org.ulid}/skus/problems`);
            return response.data.count;
        },
    };
}
