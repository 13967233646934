import type { IWarehouse } from '@/api/model/Warehouse';
import Warehouse from '@/api/model/Warehouse';
import type { IProduct, WithLeadTime } from './Product';
import Product from './Product';
import type Country from '@/api/model/Country';
import { DateTime } from 'luxon';

type SupplierShippingTime = {
    code: string;
    created_at: string;
    days: number;
    id: number;
    type: number;
    updated_at: string;
    supplier_id: number;
};

export interface SupplierJson {
    id?: number;
    created_at: string;
    updated_at: string;
    name: string;
    organisation_id: number;
    products: Array<IProduct>;
    pivot: {
        production_lead_days: number;
        buffer_days: number;
    };
    shipping_times: Array<SupplierShippingTime>;
    warehouse: IWarehouse;
}

export default class Supplier {
    id = 0;
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();
    name = '';
    organisation_id = 0;
    products: Array<Product & WithLeadTime> = [];
    pivot: {
        production_lead_days: number;
        buffer_days: number;
    } = {
        buffer_days: 0,
        production_lead_days: 0,
    };
    shipping_times: Array<SupplierShippingTime> = [];
    warehouse: Warehouse = new Warehouse();

    toJSON(): SupplierJson {
        //console.debug("Supplier.toJSON()", this);
        return Object.assign({} as SupplierJson, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: SupplierJson): Supplier {
        //console.debug("Supplier.fromJSON()", json);
        const user = Object.create(Supplier.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            products: json.products ? json.products.map((product) => Product.fromJSON(product)) : [],
            warehouse: json.warehouse ? Warehouse.fromJSON(json.warehouse) : null,
        });
    }

    getShippingTimeByCountry(country: Country): SupplierShippingTime {
        const country_times = this.shipping_times.find((shipping_time) => {
            return shipping_time.code === country.country_code;
        });

        if (country_times) {
            return country_times;
        }

        const region_times = this.shipping_times.find((shipping_time) => {
            return shipping_time.code === country.region_code;
        });

        if (region_times) {
            return region_times;
        }

        return {
            days: 0,
            code: 'N/A',
            created_at: '',
            id: 0,
            type: 0,
            updated_at: '',
            supplier_id: 0,
        };
    }
}
