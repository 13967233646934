import type { UserRegisterRequest, UserUpdatePasswordRequest } from '@/api/types/Auth';
import type { User } from '@/api/model/User';

import useAuthService from '@/api/AuthService';
import useUserService from '@/api/UserService';
import { defineStore } from 'pinia';
import { useOrganisationStore } from '@/stores/organisationStore';
import * as Sentry from '@sentry/vue';

interface UserState {
    auth: {
        loggedIn: boolean;
        userProfile: User | null;
    };
}

const authService = useAuthService();
const userService = useUserService();

export const useAuthStore = defineStore('auth', {
    state(): UserState {
        return {
            auth: {
                loggedIn: !!localStorage.getItem('loggedIn'),
                userProfile: null,
            },
        } as UserState;
    },
    getters: {
        loggedIn(): boolean {
            return this.auth.userProfile !== null;
        },
    },
    actions: {
        async login(email: string, password: string): Promise<User> {
            return await authService
                .login(email, password)
                .then(() => {
                    return this.getCurrentUser();
                })
                .catch((error) => {
                    this.setLoggedOut();
                    return Promise.reject(error);
                });
        },
        async register(credentials: UserRegisterRequest): Promise<User> {
            return await authService.register(credentials).then(async () => {
                return await this.getCurrentUser();
            });
        },
        setLoggedIn(user: User) {
            this.auth.userProfile = user;
            const organisationStore = useOrganisationStore();
            organisationStore.setOrganisations(user.organisations);
            this.auth.loggedIn = true;
            Sentry.setUser({ email: user.email });
        },
        setLoggedOut() {
            this.auth.userProfile = null;
            this.auth.loggedIn = false;
            Sentry.setUser(null);
        },
        async logout() {
            if (!this.auth.userProfile) {
                return Promise.resolve();
            }
            const response = await authService.logout();
            this.setLoggedOut();
            return response;
        },
        async createPassword(createData: UserUpdatePasswordRequest): Promise<number> {
            return await authService.createPassword(createData);
        },

        async getCurrentUser(): Promise<User> {
            if (this.auth.userProfile) {
                return Promise.resolve(this.auth.userProfile);
            }
            return await authService
                .getCurrentUser()
                .then((user) => {
                    this.setLoggedIn(user);
                    return user;
                })
                .catch((error) => {
                    this.setLoggedOut();
                    return Promise.reject(error);
                });
        },

        async updateProfile(data: FormData): Promise<User> {
            return await userService
                .updateProfile(data)
                .then((user) => {
                    this.auth.userProfile = user;
                    return user;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
    },
});
