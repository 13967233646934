import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import type { ICountry } from './Country';
import Country from './Country';
import type { PurchaseOrderJson } from './PurchaseOrder';
import PurchaseOrder from './PurchaseOrder';
import type { ISKU } from './SKU';
import SKU from './SKU';

export interface PurchaseOrderItemJson {
    id?: number;
    created_at: string;
    updated_at: string;
    quantity: number;
    quantity_shipped: number;
    production_days_copy: number;
    shipping_days_copy: number;
    buffer_days_copy: number;
    is_shipped: boolean;
    auto_eta: string;
    actual_eta: string;
    next_order_date: string;
    extra_future_order_date: string;
    notes: string;
    purchase_order_id: number;
    country_id: number;
    sku_id: number;
    sku?: ISKU;
    country?: ICountry;
    purchase_order?: PurchaseOrderJson;
    value: number;
    currency: string;
    uuid: string;
}

function to_optional_date(date: string | undefined): DateTime | undefined {
    return date == undefined ? undefined : DateTime.fromISO(date);
}
function clone_date(date: DateTime | undefined | null): DateTime | undefined {
    return date == undefined ? undefined : DateTime.fromObject(date.toObject());
}

export type WithPurchaseOrder = {
    purchase_order: PurchaseOrder;
};

export default class PurchaseOrderItem {
    id = 0;
    readonly created_at: DateTime = DateTime.now();
    readonly updated_at: DateTime = DateTime.now();
    quantity = 0;
    quantity_shipped = 0;
    readonly production_days_copy: number = 0;
    readonly shipping_days_copy: number = 0;
    readonly buffer_days_copy: number = 0;
    is_shipped = false;
    readonly auto_eta: DateTime | null = null;
    actual_eta: DateTime | null = null;
    next_order_date?: DateTime = undefined;
    extra_future_order_date?: DateTime = undefined;
    notes = '';
    readonly purchase_order_id: number = 0;
    country_id = 0;
    sku_id = 0;
    sku?: SKU = undefined;
    country?: Country = undefined;
    purchase_order?: PurchaseOrder = undefined;
    value = 0;
    currency = 'USD';
    uuid = uuidv4();

    toJSON(): PurchaseOrderItemJson {
        //console.debug("PurchaseOrderItem.toJSON()", this);
        return Object.assign({} as PurchaseOrderItemJson, this, {
            auto_eta: undefined,
            created_at: undefined,
            updated_at: undefined,
            production_days_copy: undefined,
            shipping_days_copy: undefined,
            actual_eta: this.actual_eta?.toISODate() ?? null,
            next_order_date: this.next_order_date?.toISODate() ?? null,
            extra_future_order_date: this.extra_future_order_date?.toISODate() ?? null,
        });
    }

    static fromJSON(json: PurchaseOrderItemJson): PurchaseOrderItem {
        //console.debug("PurchaseOrderItem.fromJSON()", json);
        const user = Object.create(PurchaseOrderItem.prototype);
        return Object.assign(user, json, {
            auto_eta: json.auto_eta ? DateTime.fromISO(json.auto_eta) : null,
            actual_eta: to_optional_date(json.actual_eta),
            next_order_date: to_optional_date(json.next_order_date),
            extra_future_order_date: to_optional_date(json.extra_future_order_date),
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),

            sku: json.sku ? SKU.fromJSON(json.sku) : undefined,
            country: json.country ? Country.fromJSON(json.country) : undefined,
            purchase_order: json.purchase_order ? PurchaseOrder.fromJSON(json.purchase_order) : undefined,
        });
    }

    clone(): PurchaseOrderItem {
        //console.debug("PurchaseOrderItem.fromJSON()", json);
        const user = Object.create(PurchaseOrderItem.prototype);
        return Object.assign(user, this, {
            auto_eta: clone_date(this.auto_eta),
            actual_eta: clone_date(this.actual_eta),
            next_order_date: clone_date(this.next_order_date),
            extra_future_order_date: clone_date(this.extra_future_order_date),
            created_at: clone_date(this.created_at),
            updated_at: clone_date(this.updated_at),

            sku: this.sku ? this.sku : undefined,
            country: this.country ? Country.fromJSON(this.country) : undefined,
            purchase_order: this.purchase_order ? this.purchase_order : undefined,
        });
    }

    // Handle the CInput date format. If it's removed, we are given an empty string
    get actual_eta_formatted(): string {
        return this.actual_eta?.toISODate() ?? '';
    }
    set actual_eta_formatted(date: string) {
        this.actual_eta = date === '' ? null : DateTime.fromISO(date);
    }
    // Handle the CInput date format. If it's removed, we are given an empty string
    get next_order_date_formatted(): string {
        return this.next_order_date?.toISODate() ?? '';
    }
    set next_order_date_formatted(date: string) {
        this.next_order_date = date === '' ? undefined : DateTime.fromISO(date);
    }
    get extra_future_order_date_formatted(): string {
        return this.extra_future_order_date?.toISODate() ?? '';
    }
    set extra_future_order_date_formatted(date: string) {
        this.extra_future_order_date = date === '' ? undefined : DateTime.fromISO(date);
    }

    get document_number() {
        return this.purchase_order ? this.purchase_order.document_number : 'Unknown';
    }

    get order_date_formatted() {
        return this.purchase_order ? this.purchase_order.order_date_formatted : '';
    }

    get quantityBalance() {
        return this.quantity - this.quantity_shipped;
    }
    get status() {
        if (this.is_shipped) {
            return 'Fully Shipped';
        } else if (this.quantity_shipped > 0) {
            return 'Partially Shipped';
        } else {
            return 'Current';
        }
    }

    get total_lead_time() {
        return this.production_days_copy + this.shipping_days_copy + this.buffer_days_copy;
    }

    public getProgressPercent(purchase_order: PurchaseOrder, endDate: DateTime | null): number {
        if (endDate == null) {
            return 0;
        }
        const now = DateTime.local();
        const order_date = purchase_order.order_date;
        if (now < order_date) {
            return 0;
        } else if (now > endDate) {
            return 100;
        } else {
            const days_passed = now.diff(order_date).as('days');
            const days_total = endDate.diff(order_date).as('days');
            const percent = Math.round((days_passed / days_total) * 100);
            if (percent == 0) {
                return 1;
            } // show its started even if 0.4%
            if (percent == 100) {
                return 100;
            } // show not finished even if 99.9%

            return percent;
        }
    }
}
