import { DateTime } from 'luxon';
import type { ReportSourceType } from '@/api/types/ReportSources';
import type { ISalesChannel } from '@/api/model/SalesChannel';
import SalesChannel from '@/api/model/SalesChannel';

export interface IReportSource {
    created_at: string;
    has_daily_sync: boolean;
    id: number;
    name: string;
    region_code: string;
    seller_partner_id: string;
    type: ReportSourceType;
    updated_at: string;
    uuid: string;
    auth_token: string;
    client_key: string;
    client_secret: string;
    sales_channels: ISalesChannel[];
    orders_synced_at?: string;
    shipments_synced_at?: string;
    inventory_synced_at?: string;
}

export default class ReportSource {
    created_at: DateTime = DateTime.local();
    has_daily_sync = true;
    id = 0;
    name?: string;
    region_code = 'Unknown';
    seller_partner_id?: string;
    type?: ReportSourceType;
    updated_at: DateTime = DateTime.local();
    auth_token = '';
    client_key = '';
    client_secret = '';
    uuid?: string;
    sales_channels: SalesChannel[] = [];
    orders_synced_at: DateTime | null = null;
    shipments_synced_at: DateTime | null = null;
    inventory_synced_at: DateTime | null = null;

    toJSON(): IReportSource {
        //console.debug("Report.toJSON()", this);
        return Object.assign({} as IReportSource, this, {});
    }

    static fromJSON(json: IReportSource): ReportSource {
        //console.debug("ReportSource.fromJSON()", json);
        const reportSource = Object.create(ReportSource.prototype);
        return Object.assign(reportSource, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            sales_channels: json.sales_channels ? json.sales_channels.map(SalesChannel.fromJSON) : [],
            client_key: json.client_key ? json.client_key : '',
            inventory_synced_at: json.inventory_synced_at ? DateTime.fromISO(json.inventory_synced_at) : null,
            orders_synced_at: json.orders_synced_at ? DateTime.fromISO(json.orders_synced_at) : null,
            shipments_synced_at: json.shipments_synced_at ? DateTime.fromISO(json.shipments_synced_at) : null,
        });
    }
}

export type WithReportSource = {
    report_source: ReportSource;
};
