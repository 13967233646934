import { createApp, markRaw } from 'vue';
import { createBootstrap } from 'bootstrap-vue-next';
import { createPinia } from 'pinia';
import App from './App.vue';

// You can use the following starter router instead of the default one as a clean starting point
// import router from "./router/starter";
import router from '@/router';

// Template components
import BaseBlock from '@/components/BaseBlock.vue';
import BaseBackground from '@/components/BaseBackground.vue';
import BasePageHeading from '@/components/BasePageHeading.vue';

// Template directives
import clickRipple from '@/directives/clickRipple';

// Craft new application
const app = createApp(App);

// Register global components
app.component('BaseBlock', BaseBlock);
app.component('BaseBackground', BaseBackground);
app.component('BasePageHeading', BasePageHeading);

// Register global directives
app.directive('click-ripple', clickRipple);

import Swal from 'sweetalert2';
window.Swal = Swal;

// Use Pinia and Vue Router
const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
});
app.use(pinia);
app.use(router);
app.use(createBootstrap());

// ..and finally mount it!
app.mount('#app');

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import type { AxiosError } from 'axios';
import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'Sellopod Web App';
axios.defaults.withCredentials = true;

/*
const authStore = useAuthStore();
axios.interceptors.response.use(undefined, function (error: AxiosError) {
    console.debug("Axios error", error);
    if (!error) {
        return;
    }
    const originalRequest = error.config as AxiosRequestConfig & { retry: boolean};
    if (error.response?.status === 401 && !originalRequest.retry && !error.request.responseURL.includes("api/users/me") ) {

        originalRequest.retry = true;
        authStore.logout()
            .then(() => {
                console.debug("Logged out: redirecting to login");
                return router.push({name: 'login'});
            });
    }
    return Promise.reject(error);
});
*/
// Add a default converter from an Axios error to a Laravel error
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error: unknown) {
        if (axios.isAxiosError(error)) {
            return Promise.reject(LaravelError.fromAxios(error as AxiosError<LaravelErrorJson>));
        }
        return Promise.reject(error);
    },
);

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import annotationPlugin from 'chartjs-plugin-annotation';

import type { LaravelErrorJson } from '@/api/model/LaravelError';
import LaravelError from '@/api/model/LaravelError';
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    TimeScale,
    Filler,
    annotationPlugin,
);

import * as Sentry from '@sentry/vue';

if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC as string,
        trackComponents: true,
        beforeSend(event) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
        },
        integrations: [
            Sentry.browserTracingIntegration({
                router: router,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: (import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE as number) ?? 0.2,
    });
}

import.meta.glob(['../assets/**/*.png', '../assets/**/*.ico']);
