import axios from 'axios';
import type { OrganisationJson } from '@/api/model/Organisation';
import { Organisation } from '@/api/model/Organisation';
import type { Resource } from './model/Resource';
import type {
    UpdateOrganisationConfig,
    OrganisationTeamMember,
    SuccessResponse,
    UpdateRoleRequest,
    OrganisationInviteTeamMemberRequest,
    IRedirectApiResponse,
} from './types/Organisation';
import type { ResourceCollection } from '@/api/model/ResourceCollection';
import type { ListeningEvent } from '@/api/types/ListeningEvent';

export default function useOrganisationService() {
    return {
        async updateOrganisation(
            org: Organisation,
            data: FormData,
            config: UpdateOrganisationConfig,
        ): Promise<Organisation> {
            const response = await axios.post<Resource<OrganisationJson>>(
                `/api/organisations/${org.ulid}`,
                data,
                config,
            );
            return Organisation.fromJSON(response.data.data);
        },

        async getOrganisationMembers(org: Organisation): Promise<ResourceCollection<OrganisationTeamMember>> {
            return await axios
                .get<ResourceCollection<OrganisationTeamMember>>(`/api/organisations/${org.ulid}/team`)
                .then((response) => response.data);
        },

        async removeTeamMember(organisation: Organisation, userId: number): Promise<SuccessResponse> {
            return await axios
                .delete<SuccessResponse>(`/api/organisations/${organisation.ulid}/team/${userId}`)
                .then((response) => response.data);
        },

        async updateTeamMemberRole(
            organisation: Organisation,
            userId: number,
            data: UpdateRoleRequest,
        ): Promise<SuccessResponse> {
            return await axios
                .put<SuccessResponse>(`/api/organisations/${organisation.ulid}/team/${userId}/update-role`, data)
                .then((response) => response.data);
        },

        async createTeamMember(
            form: OrganisationInviteTeamMemberRequest,
            organisation: Organisation,
        ): Promise<SuccessResponse> {
            const request_data = {
                email: form.email.split(','),
            };
            return await axios
                .post<SuccessResponse>(`/api/organisations/${organisation.ulid}/team`, request_data)
                .then((response) => response.data);
        },

        async slackAuth(organisation: Organisation, eventId: number): Promise<IRedirectApiResponse> {
            return await axios
                .get<IRedirectApiResponse>(`/api/organisations/${organisation.ulid}/events/${eventId}/slack/connect`)
                .then((response) => response.data);
        },

        async getOrganisationEvents(org: Organisation): Promise<ResourceCollection<ListeningEvent>> {
            return await axios
                .get<ResourceCollection<ListeningEvent>>(`/api/organisations/${org.ulid}/events`)
                .then((response) => response.data);
        },

        async deleteOrganisationEvent(org: Organisation, eventId: number, channelId: string): Promise<SuccessResponse> {
            return await axios
                .put<SuccessResponse>(`/api/organisations/${org.ulid}/events/${eventId}/delete`, {
                    channel_id: channelId,
                })
                .then((response) => response.data);
        },
    };
}
