import type { AxiosError } from 'axios';
import { useToastController } from 'bootstrap-vue-next';

export interface LaravelErrorJson {
    message: string;
    errors: Record<string, unknown>;
}

export default class LaravelError extends Error {
    constructor(message: string, cause: AxiosError) {
        super(message);
        this.localCause = cause;
    }

    localCause: AxiosError | null = null;
    errors: Record<string, string | string[]> = {};

    static fromAxios(err: AxiosError<LaravelErrorJson>): LaravelError {
        if (err.response) {
            const error: LaravelError = this.fromJSON(err.response.data);
            error.localCause = err;
            return error;
        }
        return new LaravelError(err.message, err);
    }

    static fromJSON(json: LaravelErrorJson): LaravelError {
        //console.debug("LaravelError.fromJSON()", json);
        const error = Object.create(LaravelError.prototype) as LaravelError;
        return Object.assign(error, json, {});
    }

    toast(message: string): void {
        const { show } = useToastController();
        console.error(message, this);
        if (!show) {
            return;
        }
        show({
            props: {
                title: 'Error',
                body: message,
                variant: 'warning',
            },
        });
    }
}
