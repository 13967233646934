import type { PurchaseOrderItemJson } from './PurchaseOrderItem';
import PurchaseOrderItem from './PurchaseOrderItem';
import type { WarehouseTransferJson } from '@/api/model/WarehouseTransfer';
import WarehouseTransfer from '@/api/model/WarehouseTransfer';
import { DateTime } from 'luxon';
import type { SupplierJson } from './Supplier';
import Supplier from './Supplier';

export interface PurchaseOrderJson {
    id?: number;
    created_at: string;
    updated_at: string;
    document_number: string;
    order_date: string;
    supplier_id: number;
    supplier: SupplierJson;
    organisation_id: number;
    items: Array<PurchaseOrderItemJson>;
    status: PurchaseOrderStatus;
    transfers: WarehouseTransferJson[];
}

export type PurchaseOrderStatus =
    | 'SHIPPED'
    | 'COMPLETED'
    | 'DRAFT'
    | 'ORDERED'
    | 'AUTHORISED'
    | 'SUBMITTED'
    | 'BILLED'
    | 'DELETED';

export default class PurchaseOrder {
    id: number = 0;
    readonly created_at: DateTime = DateTime.now();
    readonly updated_at: DateTime = DateTime.now();
    document_number = '';
    order_date: DateTime = DateTime.now();
    supplier_id = 0;
    organisation_id?: number = undefined;
    items: Array<PurchaseOrderItem> = [];
    supplier: Supplier = new Supplier();
    status: PurchaseOrderStatus = 'DRAFT';

    toJSON(): PurchaseOrderJson {
        //console.debug("PurchaseOrder.toJSON()", this);
        return Object.assign({} as PurchaseOrderJson, this, {
            order_date: this.order_date_formatted,
            created_at: undefined,
            updated_at: undefined,
            items: this.items.map((item) => item.toJSON()),
        });
    }

    static fromJSON(json: PurchaseOrderJson): PurchaseOrder {
        //console.debug("PurchaseOrder.fromJSON()", json);
        const user = Object.create(PurchaseOrder.prototype);
        return Object.assign(user, json, {
            order_date: DateTime.fromISO(json.order_date),
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            items: json.items ? json.items.map((item) => PurchaseOrderItem.fromJSON(item)) : [],
            supplier: json.supplier ? Supplier.fromJSON(json.supplier) : undefined,
            transfers: json.transfers ? json.transfers.map(WarehouseTransfer.fromJSON) : [],
        });
    }

    get order_date_formatted(): string {
        return this.order_date.toISODate() ?? 'Invalid Date';
    }
    set order_date_formatted(date: string) {
        this.order_date = DateTime.fromISO(date);
    }
}

export type WithTransfers = { transfers: WarehouseTransfer[] };
