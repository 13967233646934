import axios from 'axios';
import type { IReportSource } from './model/ReportSource';
import ReportSource from './model/ReportSource';
import type { ResourceCollection } from '@/api/model/ResourceCollection';
import type { StoreReportSourceRequest } from '@/api/types/ReportSources';
import type { Resource } from '@/api/model/Resource';
import type { Organisation } from '@/api/model/Organisation';

function filterObject(obj: { [key: string]: unknown } | object, keys: string[]): { [key: string]: unknown } {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}

export default function useReportSourceService() {
    return {
        async getAll(org: Organisation): Promise<ReportSource[]> {
            const response = await axios.get<ResourceCollection<IReportSource>>(
                `/api/organisations/${org.ulid}/report-sources`,
            );
            return response.data.data.map((reportSource) => ReportSource.fromJSON(reportSource));
        },

        async create(org: Organisation, data: StoreReportSourceRequest): Promise<ReportSource> {
            const response = await axios.post<Resource<IReportSource>>(
                `/api/organisations/${org.ulid}/report-sources`,
                data,
            );
            return ReportSource.fromJSON(response.data.data);
        },

        update(organisation: Organisation, reportSource: ReportSource) {
            const data = filterObject(reportSource, [
                'name',
                'type',
                'auth_token',
                'client_key',
                'client_secret',
                'has_daily_sync',
            ]);
            return axios
                .put<
                    Resource<IReportSource>
                >(`/api/organisations/${organisation.ulid}/report-sources/${reportSource.id}`, data)
                .then((response) => ReportSource.fromJSON(response.data.data));
        },
    };
}
